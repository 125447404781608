import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import Cards from './Components/Cards';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { RaceProvider } from './RaceContext';
import { socket } from '../../config';
import styles from './styles.module.scss';

const Race = () => {
  const { address, account } = useGetAccountInfo();
  const x = useGetAccountInfo();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const handleRedirect = () =>
    Boolean(address) ? setLoading(false) : navigate('/unlock');
  useEffect(handleRedirect, [address]);
  const [racesData, setRacesData] = useState([]);

  useEffect(() => {
    if (socket === null) return;
    socket.emit('get-races');
    socket.on('recive-races', setRacesData);
  }, [socket]);
  return (
    <>
      {racesData.length === 0 ? (
        <Container>
          <Row>
            <h1 className={styles.wrong}>
              SOMETHING WENT WRONG, PLEASE REFRESH THE PAGE
            </h1>
            <Row>
              <Col className="col-lg-6 col-m-6 col-12 my-5 text-center">
                <Row className={`${styles.loading} my-5`}>
                  <h2 className={styles.wrong}>THE EQUISTAR RUNNING</h2>
                  <div className={`${styles.bar}  ${styles.race} `}></div>
                </Row>
              </Col>
              <Col className="col-lg-6 col-m-6 col-12 my-5 text-center">
                <Row className={`${styles.loading} my-5`}>
                  <h2 className={styles.wrong}>THE EQUISTAR RUNNING</h2>
                  <div className={`${styles.bar}  ${styles.race} `}></div>
                </Row>
              </Col>
            </Row>
          </Row>
        </Container>
      ) : (
        <RaceProvider>
          <Container>
            <Row className="mt-4 d-flex justify-content-center">
              {racesData?.map((races, index) => (
                <Col className="col-12 col-md-9 col-lg-6 mb-4" key={index}>
                  <Cards races={races.races} position={index} />
                </Col>
              ))}
            </Row>
            <Row>
              <Table striped bordered className="text-white"></Table>
            </Row>
          </Container>
        </RaceProvider>
      )}
    </>
  );
};

export default Race;
