import { useEffect, useState, useContext } from "react";
import styles from "../styles.module.scss";
import Button from "pages/Race/Components/Button";
import { Container, Row, Col } from "react-bootstrap";
import RaceContext from "../RaceContext";
import { socket } from "../../../config";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";

function Table({ races }) {
  const { address } = useGetAccountInfo();
  const { nft, setNft } = useContext(RaceContext);

  useEffect(() => {
    if (socket === null) return;
    socket.emit("get-status", address);
    function handler(data) {
      setNft(data);
    }
    socket.on("recive-status", handler);
  }, []);
  return (
    <Container>
      {races.length !== 0 ? (
        <Row className={styles.removePadding}>
          <Col>
            <h4 className={styles.racePrize}>Players</h4>
          </Col>
          <Col>
            <h4 className={styles.racePrize}>Prize Pool</h4>
          </Col>
          {races.length === 5 ? (
            <Col>
              <h4 className={styles.racePrize}>Stamina</h4>
            </Col>
          ) : null}

          <Col>
            <h4 className={styles.racePrize}>Join</h4>
          </Col>
        </Row>
      ) : (
        <span className={styles.comingSoon}>?</span>
      )}
      {races.map((race) => (
        <Row key={race._doc._id} className={styles.removePadding}>
          <Col className={`text-center ${styles.racePlayersNB}`}>
            {race._doc.player.length === 0 ? 0 : race._doc.player.length}
            <span className={styles.racePlayersNBSlash}>/</span>8
          </Col>
          <Col>
            <h6 className={styles.racePlayersNB}>
              {race._doc.prizePool} ${race._doc.with}
            </h6>
          </Col>
          {race._doc.entryFee == 0 ? (
            <Col>
              <span className={styles.racePlayersNB}>{race._doc.stamina}</span>
            </Col>
          ) : null}
          <Col
            className={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button race={race._doc} />
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default Table;
