import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const Edit = ({ tournament }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <button onClick={() => setShow(!show)} className="btn btn-danger">
        <FontAwesomeIcon icon={faPencilAlt} /> Edit
      </button>
    </>
  );
};

export default Edit;
