import { useState, useEffect } from 'react';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
  transactionServices,
  sendTransactions,
  refreshAccount,
} from '@elrondnetwork/dapp-core';
import Enter from './Enter';
import { reciveAddress } from '../../../config';

import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

const TournamentModel = ({ address, socket, tournament }) => {
  const [show, setShow] = useState(false);
  const [transactionSessionId, setTransactionSessionId] = useState('');
  const [message, setMessage] = useState(null);
  const [canSendTx, setCanSendTx] = useState(false);
  const txSuccess = transactionServices.useGetSuccessfulTransactions();
  const txFail = transactionServices.useGetFailedTransactions();
  const navigate = useNavigate();
  useEffect(() => {
    for (let i = 0; i < txSuccess.successfulTransactionsArray.length; i++) {
      if (txSuccess.successfulTransactionsArray[i][0] == transactionSessionId) {
        setShow(true);
      }
    }
  }, [txSuccess.successfulTransactionsArray.length]);

  useEffect(() => {
    for (let i = 0; i < txFail.failedTransactionsArray.length; i++) {
      if (txFail.failedTransactionsArray[i][0] == transactionSessionId) {
        setShow(false);
      }
    }
  }, [txFail.failedTransactionsArray.length]);

  function numHex(s) {
    var a = s.toString(16);
    if (a.length % 2 > 0) {
      a = '0' + a;
    }
    return a;
  }
  const handle = (slots, nftsAvailable) => {
    if (slots === 0) {
      setMessage('No more slots available');
      setShow(true);
    } else if (!nftsAvailable) {
      setMessage('You no longer have horses available.');
    } else if (slots !== 0 && tournament.entryFee > 0) {
      setCanSendTx(true);
    }
  };
  useEffect(() => {
    socket.emit('get-tournament-check', tournament._id, address);
    socket.once('recive-tournament-check', handle);
  }, []);

  async function check() {
    socket.emit('get-tournament-check', tournament._id, address);
    await socket.once('recive-tournament-check', handle);
    if (canSendTx) {
      sendTransaction();
      setCanSendTx(false);
    }
    if (message !== null) {
      setShow(true);
    }
    if (tournament.entryFee === 0) {
      setShow(true);
    }
  }

  const sendTransaction = async () => {
    const estarTransaction = {
      value: 0,
      gasLimit: '500000',
      data: btoa(
        'ESDTTransfer@45535441522d616661616630@' +
          numHex(tournament.entryFee * 100)
      ),
      receiver: reciveAddress,
    };
    await refreshAccount();

    const { sessionId } = await sendTransactions({
      transactions: estarTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing transaction',
        errorMessage: 'An error has occured during',
        successMessage: 'Transaction successful',
      },
      redirectAfterSign: false,
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };
  return (
    <>
      {tournament.stages.length !== 0 ? (
        <button
          onClick={() =>
            navigate(`winners/${tournament._id}`, { state: tournament._id })
          }
          className={styles.buttonSee}
        >
          See results
        </button>
      ) : (
        <button
          className={`btn btn-primary ${
            tournament.stages.length > 0 ? 'disabled' : null
          } ${styles.buttonJoin}`}
          onClick={() => check()}
        >
          Join <FontAwesomeIcon icon={faDoorOpen} />
        </button>
      )}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        {message != null ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Ops</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>{message}</h4>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title>Select your horse</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Enter
                socket={socket}
                address={address}
                tournament={tournament}
              />
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};

export default TournamentModel;
