import React, { useState, useEffect, useMemo } from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { io } from 'socket.io-client';
import { backend, socket } from 'config';
import styles from './styles.module.scss';
import CustomTable from './Components/CustumTable';
import { Table } from 'react-bootstrap';

const RaceHistory = () => {
  const { address } = useGetAccountInfo();
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState();

  const memoValue = useMemo(
    () => ({ address, loading, history }),
    [address, loading, history]
  );

  function setDelay(ms) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  }
  async function makeLoading(ms) {
    await setDelay(ms);
    setLoading(false);
  }

  useEffect(() => {
    socket.emit('get-all_history', memoValue.address);

    const handler = async (races) => {
      setHistory(races);
      makeLoading(500);
    };
    socket.on('recive-all_history', handler);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className={styles.title}>Races History</h1>
        </div>
      </div>
      <div className="row text-light">
        <div className="col-12">
          {memoValue.loading ? (
            <>
              <Table striped bordered className="text-white">
                <thead>
                  <tr>
                    <th className={styles.headers}>Race id</th>
                    <th className={styles.headers}>Created On</th>
                    <th className={styles.headers}>End Date</th>
                    <th className={styles.headers}>View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={styles.loading}>
                      <div className={styles.bar}></div>
                    </td>
                    <td className={styles.loading}>
                      <div className={styles.bar}></div>
                    </td>
                    <td className={styles.loading}>
                      <div className={styles.bar}></div>
                    </td>
                    <td className={styles.loading}>
                      <div className={styles.bar}></div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            <div className="col-12">
              <CustomTable history={memoValue.history} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(RaceHistory);
