import React, { useEffect, useState } from "react";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import styles from "../styles.module.scss";
import { socket } from "../../../config";

const ClaimRewards = () => {
  const { address } = useGetAccountInfo();
  const [rewards, setRewards] = useState(null);

  useEffect(() => {
    if (socket == null) return;
    socket.emit("get-rewards", address);
    socket.on("recive-rewards", (rewards) => {
      setRewards(rewards);
    });
  }, [socket]);

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-12 col-lg-5 mx-auto">
          <h1 className={styles.claimTitle}>races rewards</h1>
          <div className={styles.claimData}>
            {rewards !== null ? (
              rewards.map((reward) => {
                if (reward.quantity !== 0) {
                  return (
                    <div key={reward.token} className={styles.claimDataContent}>
                      <h2 className={styles.claimDataContentEl}>
                        your&nbsp;
                        <span className={styles.claimDataContentToken}>
                          ${reward.token}
                        </span>
                      </h2>
                      <h1 className={styles.claimDataContentReward}>
                        {reward.quantity?.toFixed(2)}
                      </h1>
                    </div>
                  );
                }
              })
            ) : (
              <p className={styles.claimDataContentEl}>
                &nbsp;You do not have any rewards yet.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimRewards;
