import React, { useState, useEffect } from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { transactionServices ,sendTransactions, refreshAccount } from '@elrondnetwork/dapp-core';

const Admin = () => {

    const [transactionSessionId, setTransactionSessionId] = useState('');
    const sendTransaction = async () => {
        const estarTransaction = {
          value: 0,
          gasLimit: '6000000',
          data: btoa(
            'ESDTTransfer@45535441522D616661616630@2710@616464436C61696D@657264317130377739786d38617664376b776a3363676e3378726e687a673564613765337667376476366773336e7079716c306a70713973733335613230'
          ),
          receiver: "erd1qqqqqqqqqqqqqpgqjg4qfhs96df2z909mz7cudzav98jzm80wmfsw6y5gq"
        };
        await refreshAccount();
    
        const { sessionId } = await sendTransactions({
          transactions: estarTransaction,
          transactionsDisplayInfo: {
            processingMessage: 'Processing transaction',
            errorMessage: 'An error has occured during',
            successMessage: 'Transaction successful'
          },
          redirectAfterSign: false
        });
        if (sessionId != null) {
          setTransactionSessionId(sessionId);
        }
      };

  return (
    <div>
        <h1>Admin Panel</h1>
        <button className="btn btn-primary" onClick={sendTransaction}>Add</button>
    </div>
  )
}

export default Admin;