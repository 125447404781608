import Table from "./Table";
import styles from "../styles.module.scss";
import React, { useMemo } from "react";

function Cards({ races, position }) {
  const memoRaces = useMemo(() => races, [races]);
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div>
          <h3 className={styles.raceName}>
            THE EQUISTAR RUNNING #{position + 1}
          </h3>
          <h5 className={styles.raceDescription}>
            {position + 1 < 4
              ? " Get your Equistar horse, equip yourself and get ready to prove your riding skills in the race for 1st place. The other horses came in, what are you waiting for?"
              : "Coming soon..."}
          </h5>
          <Table races={memoRaces} />
        </div>
      </div>
    </div>
  );
}

export default React.memo(Cards);
