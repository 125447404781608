import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles.module.scss';

import user from '../../../assets/img/user (1).png';

const AccountInfo = ({ account, socket }) => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-12">
          <div className="col-12">
            <h1 className={styles.helloTitle}>
              Hello,&nbsp;
              <span className={styles.helloUsername}>
                {account.data.username}!
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 m-auto">
          <div className={styles.img}>
            <img src={user} />
          </div>
          <button
            onClick={() => navigate('/account/edit')}
            className={styles.editButton}
          >
            Edit profile
          </button>
          <div>
            {account.data.badges !== undefined ? (
              account.data.badges.map((badge) => (
                <img
                  key={badge.name}
                  src={badge.path}
                  style={{ height: 100, width: 100 }}
                />
              ))
            ) : (
              <p></p>
            )}
          </div>
        </div>
        <div className="col-12 col-md-8">
          <h1 className={styles.statisticsTitle}>Statistics</h1>
          <div className={styles.statisticsData}>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>level</h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.level}
              </p>
            </div>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>race played</h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.race_played}
              </p>
            </div>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>Xp</h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.xp}
              </p>
            </div>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>winrate</h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.winrate}%
              </p>
            </div>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>race won</h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.race_won}
              </p>
            </div>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>your nfts</h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.nfts}
              </p>
            </div>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>stable level</h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.stable_level}
              </p>
            </div>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>estar</h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.estar}
              </p>
            </div>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>
                tournament played
              </h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.tournament_played}
              </p>
            </div>
            <div className={styles.statisticsDataContent}>
              <h1 className={styles.statisticsDataContentEl}>tournament won</h1>
              <p className={styles.statisticsDataContentParagraf}>
                {account.statistics.tournament_won}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
