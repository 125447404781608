import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import styles from "./styles.module.scss";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColumnFilter from "./ColumnFilter";
import {
  faEye,
  faAngleRight,
  faAngleLeft,
  faLongArrowAltUp,
  faLongArrowAltDown,
} from "@fortawesome/free-solid-svg-icons";

function CustomTable({ data, columns, pagination, address }) {
  const sorted = useMemo(
    () => [
      {
        id: "score",
        desc: true,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorted,
        pageSize: 20,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;
  return (
    <>
      <Table striped bordered className="text-white " {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="text-center">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={styles.headers}
                >
                  {column.render("Header")}
                  <span style={{ color: "white" }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon={faLongArrowAltDown}
                          className={styles.sort}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faLongArrowAltUp}
                          className={styles.sort}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                  {/* <> {column.canFilter ? column.render("Filter") : null}</> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page?.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`text-center ${
                  row.original.address === address ? styles.yourHorse : null
                }`}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={`text-light ${styles.contentTable} `}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {pagination ? (
        <div className={styles.tableFooter}>
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className={styles.buttons}
            title="First Page"
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={styles.button}
            title="Previous Page"
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <span className={styles.numberOfPage}>
            Page: {pageIndex + 1} of {pageOptions.length}
          </span>
          <button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={styles.button}
            title="Next"
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className={styles.buttons}
            title="Last Page"
          >
            <FontAwesomeIcon icon={faAngleRight} />
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
          <p className={styles.numberOfPage}>Page size:</p>

          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className={styles.select}
          >
            {[20, 50, 100].map((size) => (
              <option key={size} value={size} className={styles.option}>
                {size}
              </option>
            ))}
          </select>
        </div>
      ) : null}
    </>
  );
}

export default CustomTable;
