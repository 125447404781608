import { useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { socket } from 'config';
import React, { useReducer } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { formReducer, INITIAL_STATE } from './formReducer';

const Fields = [
  {
    name: 'entryFee',
    label: 'Entry Fee',
    type: 'number',
    placeholder: 'Entry Fee',
  },
  {
    name: 'prizePool',
    label: 'Enter the Prize Pool',
    type: 'number',
    placeholder: 'Example: 5000',
  },
  {
    name: 'description',
    label: 'Enter the description',
    type: 'string',
    placeholder: 'Example...',
  },
  {
    name: 'stamina',
    label: 'Set the stamina',
    type: 'number',
    placeholder: 'Example: 50',
  },
  {
    name: 'prizeInToken',
    label: 'Set the token',
    type: 'string',
    placeholder: 'Example: MEX',
  },
  {
    name: 'maxPlayers',
    label: 'Max Players',
    type: 'number',
    placeholder: 'Example: 500',
  },
  {
    name: 'end',
    label: 'Set the end date of the tournament',
    type: 'string',
    placeholder: 'This format: dd/mm/yyyy HH:mm',
  },
];

const TournamentModal = ({ show, setShow }) => {
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const { address } = useGetAccountInfo();
  const handleChange = (e) => {
    dispatch({
      type: 'CHANGE_INPUT',
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const createTournament = () => {
    if (socket === null) return;
    socket.emit('create-tournament', state, address);
    socket.once('recive-create-tournament-response', (success) => {
      if (success) {
        setShow(false);
        window.location.reload();
      }
    });
  };
  return (
    <Modal
      dialogClassName="custom-dialog"
      show={show}
      onHide={() => {
        setShow(!show);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Create</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white w-100">
        <Form>
          {Fields.map((field, index) => (
            <Form.Group className="mb-3" key={index}>
              <Form.Label>{field.label}</Form.Label>
              <Form.Control
                type={field.type}
                name={field.name}
                onChange={handleChange}
                placeholder={field.placeholder}
              />
            </Form.Group>
          ))}
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              inline
              label="Daily"
              name="type"
              value="Daily"
              type="radio"
              onChange={handleChange}
              checked
            />
            <Form.Check
              inline
              label="Weekend"
              name="type"
              value="Weekend"
              type="radio"
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" onClick={createTournament}>
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default TournamentModal;
