import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { socket } from "../../../config";
import styles from "../styles.module.scss";

function EnterRace() {
  const { address } = useGetAccountInfo();
  const navigate = useNavigate();
  const [showSelect, setShowSelect] = useState(false);
  const [message, setMessage] = useState("");
  const [clickable, setClickable] = useState(true);
  const location = useLocation();
  const race = location.state.race;
  const [nft, setNft] = useState(null);
  const [active, setActive] = useState(true);
  const [count, setCount] = useState(race.maxEntryPerWallet);
  const [entries, setEntries] = useState(0);

  useEffect(() => {
    if (socket === null) return;
    socket.emit("get-status", address);
    socket.emit("get-entries", race._id, address);
    function handler(data) {
      setNft(data);
    }
    socket.on("recive-status", handler);
    socket.on("recive-entries", setEntries);
  }, [clickable]);

  const enter = async (horse, url) => {
    const data = await {
      address: address,
      horse: horse,
      horseUrl: url,
      feePaid: true,
      entryFee: race.entryFee,
      with: race.with,
      raceId: race._id,
    };
    socket.emit("enter-race", data);
    const handle = (response) => {
      setShowSelect(true);
      setMessage(response);
    };

    socket.on("recive-response", handle);
  };
  const handleEnter = (name, fileUri, target) => {
    setClickable(false);
    enter(name, fileUri);
    if (race.entryFee !== 0 || entries === race.maxEntryPerWallet - 1) {
      navigate(
        `/race/${race._id}`,
        entries === race.maxEntryPerWallet - 1 || race.entryFee !== 0
          ? {
              state: {
                message: `${
                  entries === race.maxEntryPerWallet - 1
                    ? "You rich the max number of entry"
                    : `You enter in race with ${target}`
                }`,
              },
            }
          : null
      );
    }
    setActive(target);
  };
  return (
    <Col>
      <Row className="col-12 col-md-4 text-center">
        <h1 style={{ color: "white" }}>Select a horse</h1>
      </Row>
      <Row>
        {nft?.map(({ fileUri, name, stamina, inRace }) => {
          if (!inRace && stamina >= race.stamina) {
            return (
              <Col
                key={name}
                className="col-12 col-md-4 text-center"
                onClick={(e) => {
                  setActive(false);
                  handleEnter(name, fileUri, e.target.name);
                }}
                style={{
                  pointerEvents: active === name ? "none" : "auto",
                }}
              >
                <img
                  src={fileUri}
                  className="d-block mx-auto"
                  height="350px"
                  name={name}
                />
                <p style={{ color: "white" }}>{name}</p>
                <p style={{ color: "white" }}>Stamina: {stamina}</p>
              </Col>
            );
          }
        })}
        <div className="col-12 col-md-4">
          <Modal
            show={showSelect}
            backdrop="static"
            keyboard={false}
            size="md-down"
            onHide={() => setShowSelect(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onClick={() => setClickable(true)}
          >
            <Modal.Header className="bg-white" closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                🎉 Congratulations!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-white px-3">
              <h4>{message.message}</h4>
            </Modal.Body>
            <Modal.Footer className="bg-white">
              <button
                className="btn btn-primary d-block mx-auto"
                onClick={() => navigate(`/race/${race._id}`)}
              >
                View race.
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        {nft === null || nft.length === 0 ? (
          <Container>
            <Row>
              <h1 className={styles.wrong}>Select a horse</h1>
              <Row className="justify-content-center">
                <Col className="col-lg-3 col-m-3 col-6 col-s-12 mx-5 text-center">
                  <Row className={`${styles.loading} my-5`}>
                    <h2 className={styles.wrong}>ESTAR Horse</h2>
                    <div className={`${styles.bar}  ${styles.race} `}></div>
                  </Row>
                </Col>
                <Col className="col-lg-3 col-m-3 col-6 col-s-12 mx-5 text-center">
                  <Row className={`${styles.loading} my-5`}>
                    <h2 className={styles.wrong}>ESTAR Horse</h2>
                    <div className={`${styles.bar}  ${styles.race} `}></div>
                  </Row>
                </Col>
                <Col className="col-lg-3 col-m-3 col-6 col-s-12 mx-5 text-center">
                  <Row className={`${styles.loading} my-5`}>
                    <h2 className={styles.wrong}>ESTAR Horse</h2>
                    <div className={`${styles.bar}  ${styles.race} `}></div>
                  </Row>
                </Col>
                <Col className="col-lg-3 col-m-3 col-6 col-s-12 mx-5 text-center">
                  <Row className={`${styles.loading} my-5`}>
                    <h2 className={styles.wrong}>ESTAR Horse</h2>
                    <div className={`${styles.bar}  ${styles.race} `}></div>
                  </Row>
                </Col>
              </Row>
            </Row>
          </Container>
        ) : null}
      </Row>
    </Col>
  );
}

export default EnterRace;
