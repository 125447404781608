import { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faAngleRight,
  faAngleLeft,
  faLongArrowAltUp,
  faLongArrowAltDown,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../styles.module.scss";
import { sortHistory } from "./SortDate";

const CustomTable = (props) => {
  const data = useMemo(() => props?.history, [props.history]);
  const sorted = useMemo(
    () => [
      {
        id: "startDate",
        desc: true,
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Race id",
        accessor: "id",
        disableSortBy: true,
      },
      {
        Header: "Created On",
        accessor: (date) => sortHistory(date.startDate),
        Cell: ({ value }) => value.split("T").join(" "),
      },
      {
        Header: "End Date",
        accessor: (date) => sortHistory(date.endDate),
        Cell: ({ value }) => value.split("T").join(" "),
      },
      {
        Header: "View",
        id: "view",
        Cell: (cell) => (
          <Link to={"/race/" + cell.row.original.id} className={styles.eye}>
            <FontAwesomeIcon icon={faEye} />
          </Link>
        ),
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorted,
        pageSize: 20,
      },
    },
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;

  return (
    <>
      <Table striped bordered className="text-white" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="text-center">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={styles.headers}
                >
                  {column.render("Header")}
                  <span style={{ color: "white" }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon={faLongArrowAltDown}
                          className={styles.sort}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faLongArrowAltUp}
                          className={styles.sort}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="text-center">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={`text-light ${styles.contentTable} `}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className={styles.tableFooter}>
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className={styles.buttons}
          title="First Page"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className={styles.button}
          title="Previous Page"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <span className={styles.numberOfPage}>
          Page: {pageIndex + 1} of {pageOptions.length}
        </span>
        <button
          type="button"
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className={styles.button}
          title="Next"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className={styles.buttons}
          title="Last Page"
        >
          <FontAwesomeIcon icon={faAngleRight} />
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
        <p className={styles.numberOfPage}>Page size:</p>

        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          className={styles.select}
        >
          {[20, 50, 100].map((size) => (
            <option key={size} value={size} className={styles.option}>
              {size}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
export default CustomTable;
