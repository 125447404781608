import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import { createContext, useState } from "react";
const RaceContext = createContext();

export function RaceProvider({ children }) {
  const [nft, setNft] = useState([]);
  const [socket, setSocket] = useState();
  const [allRaces, setRaces] = useState([]);
  const [inRace, setInRace] = useState(false);
  const [newPlayer, setNewPlayer] = useState(0);
  return (
    <RaceContext.Provider
      value={{
        nft: nft,
        setNft: setNft,
        socket: socket,
        setSocket: setSocket,
        allRaces: allRaces,
        setRaces: setRaces,
        inRace: inRace,
        setInRace: setInRace,
        newPlayer: newPlayer,
        setNewPlayer: setNewPlayer,
      }}
    >
      {children}
    </RaceContext.Provider>
  );
}

export default RaceContext;
