import { tr } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useTable } from "react-table";
import CustomTable from "../../../components/Table/CustomTable";
import ColumnFilter from "../../../components/Table/ColumnFilter";
import styles from "./styles.module.scss";
import { socket } from "../../../config";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";

function Winners() {
  const { pathname } = useLocation();
  const [stages, setStages] = useState([]);
  const { address } = useGetAccountInfo();
  useEffect(() => {
    socket.emit("get-tournament", pathname.slice(20));
    socket.on("recive-tournament", (data) => setStages(data.stages));
  }, []);
  let index = 1;
  const columnsWinners = useMemo(
    () => [
      {
        Header: "Position",
        id: "position",
        Cell: () => <>{index++}</>,
        Filter: false,
      },
      {
        Header: "Horse",
        accessor: "horse",
        Filter: ColumnFilter,
        disableSortBy: true,
      },
      {
        Header: "Score",
        accessor: "score",
        Cell: (cell) => <>{cell.value.toFixed(2)}</>,
        Filter: false,
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        Header: "Horse",
        accessor: "horse",
        Filter: ColumnFilter,
        disableSortBy: true,
      },
      {
        Header: "Score",
        accessor: "score",
        Cell: (cell) => <>{cell.value.toFixed(2)}</>,
        Filter: false,
      },
    ],
    []
  );
  return (
    <>
      <Container>
        {stages.length !== 0 ? (
          <>
            <Row>
              <Col>
                <h1 className={styles.winners}>Winners</h1>
                <CustomTable
                  data={stages[2].players}
                  columns={columnsWinners}
                  pagination={false}
                  address={address}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-around mt-5 flex-wrap">
              <Col xs={12} md={5}>
                <h2 className={styles.stage}>Second Stage</h2>
                <CustomTable
                  data={stages[1].players}
                  columns={columns}
                  pagination={true}
                  address={address}
                />
              </Col>
              <Col xs={12} md={5}>
                <h2 className={styles.stage}>First Stage</h2>
                <CustomTable
                  data={stages[0].players}
                  columns={columns}
                  pagination={true}
                  address={address}
                />
              </Col>
            </Row>
          </>
        ) : null}
      </Container>
    </>
  );
}

export default Winners;
