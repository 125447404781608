import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { transactionServices, refreshAccount } from "@elrondnetwork/dapp-core";
import { reciveAddress } from "../../../config";
import { Modal } from "react-bootstrap";
import { guardValueIsSetWithMessage } from "@elrondnetwork/erdjs/out";

function ModalPay({
  estarBalance,
  egldBalance,
  race,
  show,
  tokenName,
  setShow,
  message,
  setMessage,
}) {
  const navigate = useNavigate();
  const [transactionSessionId, setTransactionSessionId] = useState("");
  const [paid, setPaid] = useState(false);
  const { sendTransactions } = transactionServices;
  const [estar, setEstar] = useState(0);
  const txSuccess = transactionServices.useGetSuccessfulTransactions();

  useEffect(() => {
    for (let i = 0; i < txSuccess.successfulTransactionsArray.length; i++) {
      if (
        txSuccess.successfulTransactionsArray[i][0] === transactionSessionId
      ) {
        setPaid(true);
      }
    }
  }, [txSuccess.successfulTransactionsArray.length]);

  useEffect(() => {
    if (paid)
      navigate(`/enter/race/${race._id}`, {
        state: { race: race, paid: { paid: true } },
      });
  }, [paid]);
  const encrypt = (fee) => {
    return (fee * 100).toString(16);
  };

  const sendEstarTransaction = async (fee) => {
    const estarTransaction = {
      value: 0,
      gasLimit: "500000",
      data:
        estar >= 50 && estar <= 656
          ? btoa("ESDTTransfer@45535441522d616661616630@" + encrypt(fee))
          : btoa("ESDTTransfer@45535441522d616661616630@0" + encrypt(fee)),
      receiver: reciveAddress,
    };
    await refreshAccount();

    const { sessionId } = await sendTransactions({
      transactions: estarTransaction,
      transactionsDisplayInfo: {
        processingMessage: "Processing transaction",
        errorMessage: "An error has occured during",
        successMessage: "Transaction successful",
      },
      redirectAfterSign: false,
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  const sendEgldTransaction = async () => {
    const egldTransaction = {
      value: race.entryFee * 1000000000000000000,
      gasLimit: "70000",
      data: "QG9r",
      receiver: reciveAddress,
    };
    await refreshAccount();

    const { sessionId } = await sendTransactions({
      transactions: egldTransaction,
      transactionsDisplayInfo: {
        processingMessage: "Processing transaction",
        errorMessage: "An error has occured during",
        successMessage: "Transaction successful",
      },
      redirectAfterSign: true,
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };
  return (
    <Modal
      dialogClassName="custom-dialog"
      show={show}
      onHide={() => {
        setShow(!show);
        setMessage("");
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {(race.with === "ESTAR" && estarBalance < 800) ||
      (race.with === "EGLD" && egldBalance < 0.05) ||
      message !== "OK" ? (
        <>
          {" "}
          <Modal.Header closeButton className="bg-white">
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-white w-100">
            <h4>
              {race.with === "ESTAR" && estarBalance < 800
                ? `you don't have enough Estar`
                : race.with !== "ESTAR" && egldBalance < 0.05
                ? `you don't have enough EGLD`
                : message}
            </h4>
            {race.with === "ESTAR" && estarBalance < 800 ? (
              <a
                className="text-muted"
                href="https://esdt.market/app/esdt/listings?swapToken=ESTAR-afaaf0&paymentToken=EGLD"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy Estar
              </a>
            ) : race.with !== "ESTAR" && egldBalance < 0.05 ? (
              <a
                className="text-muted"
                href="https://buy.elrond.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy EGLD
              </a>
            ) : null}
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton className="bg-white">
            <Modal.Title>Pay</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-white w-100">
            <button
              className="d-block mx-auto btn btn-primary"
              onClick={() => {
                setShow(false);
                race.with === "ESTAR"
                  ? sendEstarTransaction(race.entryFee)
                  : sendEgldTransaction(race.entryFee);
              }}
            >
              {race.with === "ESTAR"
                ? ` ${race.entryFee} ${tokenName}`
                : `${race.entryFee} EGLD`}
            </button>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}

export default ModalPay;
