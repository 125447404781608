import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import { socket } from "../../config";
import Card from "./Components/Card";
import { Col, Container, Row, Table } from "react-bootstrap";
import CustomTable from "./page/CustomTable";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Create from "./Components/Create";
const Tournament = () => {
  const { address } = useGetAccountInfo();
  const navigate = useNavigate();
  const soon = false;
  const [loading, setLoading] = useState(true);
  const [tournaments, setTournaments] = useState(null);
  const [tournamentsHistory, setTournamentsHistory] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [tournamentHistory, setTournamentHistory] = useState([]);
  const handleRedirect = () =>
    Boolean(address) ? setLoading(false) : navigate("/unlock");

  useEffect(handleRedirect, [address]);

  useEffect(() => {
    if (socket === null) return;
    socket.emit("get-tournaments");
    socket.emit("get-tournaments-history");
    socket.emit("get-tournament", 2);
    socket.emit("get-is-admin", address);

    socket.on("recive-tournaments", setTournaments);
    socket.on("recive-tournament", setTournamentHistory);
    socket.on("recive-tournaments-history", setTournamentsHistory);
    socket.on("recive-is-admin", setAdmin);
  }, []);
  const columnsTournamentsHistory = useMemo(
    () => [
      {
        Header: "Type of tournament",
        accessor: "type",
        Filter: false,
      },
      {
        Header: "Players",
        accessor: "players",
        Cell: ({ value }) => value.length,
        Filter: false,
      },
      {
        Header: "Entry Fee ",
        accessor: "entryFee",
        Filter: false,
      },
      {
        Header: "Max Players",
        accessor: "maxPlayers",
        disableSortBy: true,
      },
      {
        Header: "Prize Pool",
        accessor: "prizePool",
        disableSortBy: true,
      },
      {
        Header: "Start Date",
        accessor: "date",
        disableSortBy: true,
      },
      {
        Header: "End Date",
        accessor: "end",
        disableSortBy: true,
      },
      {
        Header: "View",
        accessor: "_id",
        Cell: ({ value }) => (
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => navigate(`winners/${value}`)}
          />
        ),
      },
    ],
    []
  );
  return (
    <>
      {tournamentsHistory.length === 0 ? (
        <>
          <Container>
            <Row>
              <h1 className={styles.history}>TOURNAMENT</h1>
              <Row className={`${styles.loading} my-5`}>
                <div
                  className={`${styles.bar} ${styles.tournamentSkeleton}  `}
                ></div>
              </Row>
            </Row>
            <Table striped bordered className="text-white">
              <thead>
                <tr>
                  <th className={styles.headers}>Type of tournament</th>
                  <th className={styles.headers}>Players</th>
                  <th className={styles.headers}>Entry Fee</th>
                  <th className={styles.headers}>Max Players</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.loading}>
                    <div className={styles.bar}></div>
                  </td>
                  <td className={styles.loading}>
                    <div className={styles.bar}></div>
                  </td>
                  <td className={styles.loading}>
                    <div className={styles.bar}></div>
                  </td>
                  <td className={styles.loading}>
                    <div className={styles.bar}></div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </>
      ) : (
        <Container>
          {admin && (
            <Row>
              <Col>
                <Create />
              </Col>
            </Row>
          )}
          <Row>
            {tournaments?.map((tournament) => (
              <Col className="col-lg-6 col-12" key={tournament._id}>
                <Card
                  active={tournament.isActive}
                  tournament={tournament}
                  address={address}
                  socket={socket}
                  isAdmin={admin}
                />
              </Col>
            ))}
          </Row>
          <Row className={`my-5 overflow-auto ${styles.noScrollBarr}`}>
            <h2 className={styles.history}>History</h2>
            <CustomTable
              data={tournamentsHistory}
              columns={columnsTournamentsHistory}
              pagination={true}
            />
          </Row>
        </Container>
      )}
    </>
  );
};

export default Tournament;
