import { io } from 'socket.io-client';
interface NetworkType {
  id: 'devnet' | 'testnet' | 'mainnet';
  name: string;
  egldLabel: string;
  walletAddress: string;
  gatewayAddress: string;
  explorerAddress: string;
  apiAddress: string;
}

export const minDust = '5000000000000000';
export const backend = 'https://equistar.estar.games:4001';
export const tokenName = 'ESTAR';
export const reciveAddress =
  'erd1szcgm7vq3tmyxfgd4wd2k2emh59az8jq5jjpj9799a0k59u0wmfss4vw3v';
export const version = 'V. Alpha0.4.12';
export const dAppName = 'Estar';
export const walletConnectBridge = 'https://bridge.walletconnect.org';
export const walletConnectDeepLink =
  'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/';

export const network: NetworkType = {
  id: 'mainnet',
  name: 'Mainnet',
  egldLabel: 'EGLD',
  walletAddress: 'https://wallet.elrond.com/dapp/init',
  apiAddress: 'https://api.elrond.com',
  gatewayAddress: 'https://gateway.elrond.com',
  explorerAddress: 'https://explorer.elrond.com',
};
export const socket = io(backend);
