import * as React from "react";

const Stable1 = () => (
  <svg
    id="Layer_4"
    data-name="Layer 4"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 3052.88 2605.5"
    width="250" height="250"
  >
    <defs>
      <style>
        {
          ".cls-1{fill:#f5edc8;}.cls-2{fill:#bd0014;}.cls-3{fill:#921e11;}.cls-4{fill:#2e4152;}.cls-5{fill:#5e3f2b;}"
        }
      </style>
    </defs>
    <rect
      className="cls-1"
      x={1743.93}
      y={1435.82}
      width={1411.71}
      height={2131.45}
      transform="translate(4398.27 -550.14) rotate(90)"
    />
    <path
      className="cls-1"
      d="M3092.1,860l-29.45-25.56a8.74,8.74,0,0,0-2.53-1.5L2461.73,604.1a34.47,34.47,0,0,0-24.22,0L1839.13,832.9a8.66,8.66,0,0,0-2.54,1.5L1807.14,860a6.77,6.77,0,0,0-1.64,2.09L1293.92,1889.9c-2.21,4.46,1.82,9.32,7.73,9.32H3597.59c5.91,0,9.95-4.86,7.73-9.32L3093.74,862.05A6.77,6.77,0,0,0,3092.1,860Z"
      transform="translate(-553.06 -601.9)"
    />
    <polygon
      className="cls-1"
      points="74.43 2605.5 74.43 1774.79 893.75 1404.67 893.75 2605.5 74.43 2605.5"
    />
    <path
      className="cls-2"
      d="M3507.54,1831.45l-91.88-184.59-25.47-51.19-133.39-268-25.47-51.14-133.39-268-25.47-51.15-38.33-77h.38l-95.45-36.49-25.47-9.74-133.33-51-25.47-9.74-12.73-4.86-120.66-46.12-25.47-9.79-122.57-46.84a60.52,60.52,0,0,0-10.83-3.06,67.14,67.14,0,0,0-25.47,0,58,58,0,0,0-11.14,3.11l-122.25,46.74-25.47,9.73-121,46.28-12.35,4.71-25.47,9.73-133.39,51-25.47,9.78-95.77,36.6h.32l-37.95,76.25-25.47,51.19L1660,1292.49l-25.47,51.14-125.06,251.26L1484,1646.08l-92.27,185.37c-.11.24-.13.49-.23.73h-1l-32.55,67,20.67,84.73-7.93,3.36-25.47,10.77-133.4,56.53-25.46,10.76-127.41,54-6,2.54-24.83,10.56-.64.26-143.77,60.87-25.47,10.82-123,52.07-25.47,10.82-35.14,14.86-30.82,13.1-16.24,6.88v853.48H3440V1847.45h54.28C3504.49,1847.45,3511.36,1839.11,3507.54,1831.45Z"
      transform="translate(-553.06 -601.9)"
    />
    <polygon
      className="cls-3"
      points="956.34 992.99 956.34 2553.73 930.87 2553.73 930.87 1045.59 956.34 992.99"
    />
    <polygon
      className="cls-3"
      points="1274.07 354.64 1274.07 2553.73 1248.6 2553.73 1248.6 405.83 1274.07 354.64"
    />
    <polygon
      className="cls-3"
      points="1081.42 2553.72 1081.42 1245.54 1081.42 741.72 1106.89 690.58 1106.89 1245.54 1106.89 2553.72 1081.42 2553.72"
    />
    <polygon
      className="cls-3"
      points="1591.79 171.29 1591.79 2562.53 1566.33 2562.53 1566.33 181.03 1591.79 171.29"
    />
    <polygon
      className="cls-3"
      points="182.1 1654.58 182.1 2553.72 156.63 2553.72 156.63 1665.4 182.1 1654.58"
    />
    <polygon
      className="cls-3"
      points="474.36 1530.81 474.36 2553.72 499.83 2553.72 499.83 1519.99 474.36 1530.81"
    />
    <polygon
      className="cls-3"
      points="792.09 1396.17 792.09 2553.72 817.56 2553.72 817.56 1385.41 792.09 1396.17"
    />
    <polygon
      className="cls-3"
      points="633.23 1463.47 633.23 2553.72 658.7 2553.72 658.7 1452.7 633.23 1463.47"
    />
    <polygon
      className="cls-3"
      points="330.59 1591.69 330.59 2553.72 305.12 2553.72 305.12 1602.51 330.59 1591.69"
    />
    <path
      className="cls-3"
      d="M2462.52,662.68V3164.43h-25.47V662.63A67.14,67.14,0,0,1,2462.52,662.68Z"
      transform="translate(-553.06 -601.9)"
    />
    <polygon
      className="cls-3"
      points="1432.96 232 1432.96 2571.22 1407.49 2571.22 1407.49 241.79 1432.96 232"
    />
    <polygon
      className="cls-3"
      points="1750.62 110.57 1750.62 2571.22 1725.15 2571.22 1725.15 120.3 1750.62 110.57"
    />
    <polygon
      className="cls-3"
      points="2068.35 120.45 2068.35 2571.22 2042.88 2571.22 2042.88 110.67 2068.35 120.45"
    />
    <polygon
      className="cls-3"
      points="2386.01 241.89 2386.01 2571.22 2360.54 2571.22 2360.54 232.16 2386.01 241.89"
    />
    <polygon
      className="cls-3"
      points="2678.27 2553.73 2678.27 1245.53 2678.27 674.58 2703.74 725.75 2703.74 1245.53 2703.74 2553.73 2678.27 2553.73"
    />
    <polygon
      className="cls-3"
      points="2227.18 181.18 2227.18 2562.53 2201.72 2562.53 2201.72 171.45 2227.18 181.18"
    />
    <polygon
      className="cls-3"
      points="2519.41 2553.73 2519.41 1245.53 2519.41 355.41 2544.88 406.58 2544.88 1245.53 2544.88 2553.73 2519.41 2553.73"
    />
    <polygon
      className="cls-3"
      points="2837.13 2553.73 2837.13 1245.53 2837.13 993.75 2862.6 1044.97 2862.6 1245.53 2862.6 2553.73 2837.13 2553.73"
    />
    <rect
      className="cls-1"
      x={2182.54}
      y={965.51}
      width={534.65}
      height={1097.89}
      transform="translate(3411.26 -1537.32) rotate(90)"
    />
    <rect
      className="cls-4"
      x={1438.43}
      y={710.45}
      width={916.76}
      height={404.2}
    />
    <rect
      className="cls-1"
      x={1908.63}
      y={2120.91}
      width={981.24}
      height={1191.74}
      transform="translate(4562.97 -284.37) rotate(90)"
    />
    <rect
      className="cls-4"
      x={1316.99}
      y={1676.02}
      width={1058.41}
      height={877.71}
    />
    <rect
      className="cls-1"
      x={1624.29}
      y={2405.25}
      width={975.65}
      height={617.47}
      transform="translate(4273.04 -0.03) rotate(90)"
    />
    <rect
      className="cls-5"
      x={1323.13}
      y={1676.32}
      width={471.84}
      height={871.52}
    />
    <polygon
      className="cls-1"
      points="1364.37 2568.44 1296.43 2548.68 1753.74 1655.72 1821.67 1675.48 1364.37 2568.44"
    />
    <polygon
      className="cls-1"
      points="1753.74 2568.44 1821.67 2548.68 1364.37 1655.72 1296.43 1675.48 1753.74 2568.44"
    />
    <rect
      className="cls-1"
      x={2222.41}
      y={2416.05}
      width={975.65}
      height={595.87}
      transform="translate(4871.16 -598.15) rotate(90)"
    />
    <rect
      className="cls-5"
      x={1929.51}
      y={1676.32}
      width={455.34}
      height={871.52}
    />
    <polygon
      className="cls-1"
      points="1969.3 2568.44 1903.74 2548.68 2345.05 1655.72 2410.61 1675.48 1969.3 2568.44"
    />
    <polygon
      className="cls-1"
      points="862.67 1369.78 32.28 1718.77 0 1668 830.39 1319.01 862.67 1369.78"
    />
    <polygon
      className="cls-1"
      points="2345.05 2568.44 2410.61 2548.68 1969.3 1655.72 1903.74 1675.48 2345.05 2568.44"
    />
    <rect
      className="cls-1"
      x={853.12}
      y={2436.07}
      width={453.86}
      height={457.43}
      transform="translate(3191.78 982.83) rotate(90)"
    />
    <rect
      className="cls-4"
      x={361.95}
      y={1887.72}
      width={330.08}
      height={350.34}
    />
    <rect
      className="cls-1"
      x={491.85}
      y={1865.35}
      width={71.65}
      height={395.06}
    />
    <rect
      className="cls-1"
      x={1058.5}
      y={2456.2}
      width={47.69}
      height={417.18}
      transform="translate(-2135.5 3145.23) rotate(-90)"
    />
    <rect
      className="cls-3"
      x={1231.98}
      y={1805.71}
      width={103.15}
      height={58.98}
      rx={1.68}
    />
    <rect
      className="cls-3"
      x={2371.77}
      y={1805.71}
      width={103.15}
      height={58.98}
      rx={1.68}
    />
    <rect
      className="cls-3"
      x={1231.98}
      y={2361.63}
      width={103.15}
      height={59.84}
      rx={1.02}
    />
    <rect
      className="cls-3"
      x={2378.14}
      y={2361.63}
      width={103.15}
      height={59.84}
      rx={1.02}
    />
  </svg>
);

export default Stable1;
