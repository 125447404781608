import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TournamentModel from './TournamentModel';
import styles from './styles.module.scss';
import Edit from './Edit';
const Card = ({ active, tournament, address, socket, isAdmin }) => {
  const [loading, setLoading] = useState(true);
  const [tournamentData, setTournamentData] = useState();

  // Tournament status
  useEffect(() => {
    if (tournament != null) {
      setTournamentData(tournament);

      setLoading(false);
    }
  }, [tournament]);
  function StartTournament(tournamentId) {
    socket.emit('start-tournament', tournamentId);
    alert(`Turneul cu id-ul ${tournamentId} a pornit!`);
  }

  function setInactive(tournamentId) {
    socket.emit('set-inactive-tournament', tournamentId);
  }
  if (loading) {
    return (
      <div className="d-flex justify-content-center text-white">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else {
    if (active) {
      return (
        <>
          <Container className="my-5">
            <Row>
              <Col className="card ">
                <Row className={`justify-content-center ${styles.cardHeader} `}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {tournament.type.toUpperCase()} TOURNAMENT!{' '}
                    {isAdmin && <Edit tournament={tournament} />}
                  </div>
                  {isAdmin && (
                    <>
                      {tournament.isActive && tournament.stages.length === 0 && (
                        <button
                          className="btn btn-primary mb-1"
                          onClick={() => StartTournament(tournament._id)}
                        >
                          Start
                        </button>
                      )}
                      {tournament.isActive && tournament.stages.length > 0 && (
                        <button
                          className="btn btn-warning text-dark"
                          onClick={() => setInactive(tournament._id)}
                        >
                          Set inactive
                        </button>
                      )}
                    </>
                  )}
                </Row>
                <Row className={`${styles.cardBody} px-5 `}>
                  <h5 className={styles.cardTitle}>
                    <strong>{tournament.description}</strong>
                  </h5>

                  <Row>
                    <Col>
                      <p className={styles.cardText}>Fee </p>
                    </Col>
                    <Col className={styles.cardValue}>
                      {tournamentData.entryFee === 0
                        ? tournament.stamina + ' STAMINA'
                        : tournamentData.entryFee + ' ESTAR'}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className={styles.cardText}>Players: </p>
                    </Col>
                    <Col className={styles.cardValue}>
                      {tournamentData.players.length}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className={styles.cardText}>Max players </p>
                    </Col>
                    <Col className={styles.cardValue}>
                      {tournamentData.maxPlayers}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className={styles.cardText}>Prize Pool: </p>
                    </Col>
                    <Col className={styles.cardValue}>
                      {tournamentData.prizePool} {tournament.prizeInToken}
                    </Col>
                  </Row>
                </Row>
                <Row className="card-footer text-muted px-5">
                  End date: {tournamentData.end}
                </Row>
              </Col>
            </Row>
            <Row className="text-center">
              <div>
                <TournamentModel
                  tournament={tournament}
                  address={address}
                  socket={socket}
                />
              </div>
            </Row>
          </Container>
        </>
      );
    } else {
      return (
        <div className="card text-center">
          <div className="card-header">Tournament</div>
          <div className="card-body">
            <h5 className="card-title">
              <strong>Coming SOON</strong>
            </h5>
            <p className="card-text">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
          </div>
          <div className="card-footer text-muted">Soon</div>
        </div>
      );
    }
  }
};

export default Card;
