import React from 'react';

const Logo = (props: any) => (
  <svg
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 648.4 648.4'
    {...props}
  >
    <circle
      cx={324.2}
      cy={324.2}
      r={319.2}
      style={{
        strokeMiterlimit: 10,
        stroke: '#021b31',
        strokeWidth: 10,
        fill: '#fff'
      }}
    />
    <path
      d='M631.3 324.2c-.073 169.623-137.639 307.07-307.262 306.998A306.946 306.946 0 0 1 17.3 324.2c0-6.1.2-12.2.5-18.3a308.78 308.78 0 0 1 11.9-68.5c34.7-118 138.5-206.5 264.6-218.8a298.3 298.3 0 0 1 30-1.5c9.4 0 18.6.4 27.8 1.2 127.4 11.4 232.3 100.6 267 219.8a311.05 311.05 0 0 1 11.7 68c.3 6.1.5 12.1.5 18.1Z'
      style={{
        fill: '#052146'
      }}
    />
    <path
      d='M480.6 431.4 519 561.5a307.22 307.22 0 0 1-92.9 52.4l-102.5-73.2-102.1 73a309.65 309.65 0 0 1-93.3-53l38.4-131L17.6 306a308.778 308.778 0 0 1 11.88-68.5l187-1.6 77.6-217.1a298.329 298.329 0 0 1 30-1.5c9.4 0 18.6.4 27.8 1.2l75.8 219.8h191.3a311.052 311.052 0 0 1 11.7 68Z'
      style={{
        fill: '#021b31'
      }}
    />
    <path
      d='M100.87 302.23h21.19v-51.25h-21.51v-42.07h210.06v80.35h-66.44v-38.28h-55.69v51.25h54.09v42.07h-54.09v51.57h55.67v-38.28h66.44v80.35H100.55v-42.07h21.51V344.3h-21.19Zm266.36 39.54a43.339 43.339 0 0 1-11.23-7 45.139 45.139 0 0 1-8.86-10.12 22.53 22.53 0 0 1-3.66-12.3v-71.49a22.53 22.53 0 0 1 3.63-12.34 44.93 44.93 0 0 1 8.86-10.12 42.81 42.81 0 0 1 11.23-7 28.72 28.72 0 0 1 11.07-2.53h138.59a28.64 28.64 0 0 1 11.07 2.53 43 43 0 0 1 11.24 7 44.88 44.88 0 0 1 8.85 10.12 22.46 22.46 0 0 1 3.64 12.34v41.75h-66.43v-31.63h-75.29v51.25h106.92a28.642 28.642 0 0 1 11.07 2.53 43.558 43.558 0 0 1 11.24 7 45.1 45.1 0 0 1 8.85 10.12 22.46 22.46 0 0 1 3.64 12.34v71.81a22.46 22.46 0 0 1-3.64 12.34 45.1 45.1 0 0 1-8.85 10.12 43.558 43.558 0 0 1-11.24 7 28.64 28.64 0 0 1-11.07 2.53H378.3a28.72 28.72 0 0 1-11.07-2.53 43.339 43.339 0 0 1-11.23-7 45.139 45.139 0 0 1-8.86-10.12 22.53 22.53 0 0 1-3.66-12.38v-42.07h66.43v31.95h75.29V344.3H378.3a28.72 28.72 0 0 1-11.07-2.53Z'
      style={{
        fill: '#fff'
      }}
    />
    <path
      d='M100.87 302.23h21.19v-51.25h-21.51v-42.07h210.06v80.35h-66.44v-38.28h-55.69v51.25h54.09v42.07h-54.09v51.57h55.67v-38.28h66.44v80.35H100.55v-42.07h21.51V344.3h-21.19Zm266.36 39.54a43.339 43.339 0 0 1-11.23-7 45.139 45.139 0 0 1-8.86-10.12 22.53 22.53 0 0 1-3.66-12.3v-71.49a22.53 22.53 0 0 1 3.63-12.34 44.93 44.93 0 0 1 8.86-10.12 42.81 42.81 0 0 1 11.23-7 28.72 28.72 0 0 1 11.07-2.53h138.59a28.64 28.64 0 0 1 11.07 2.53 43 43 0 0 1 11.24 7 44.88 44.88 0 0 1 8.85 10.12 22.46 22.46 0 0 1 3.64 12.34v41.75h-66.43v-31.63h-75.29v51.25h106.92a28.642 28.642 0 0 1 11.07 2.53 43.558 43.558 0 0 1 11.24 7 45.1 45.1 0 0 1 8.85 10.12 22.46 22.46 0 0 1 3.64 12.34v71.81a22.46 22.46 0 0 1-3.64 12.34 45.1 45.1 0 0 1-8.85 10.12 43.558 43.558 0 0 1-11.24 7 28.64 28.64 0 0 1-11.07 2.53H378.3a28.72 28.72 0 0 1-11.07-2.53 43.339 43.339 0 0 1-11.23-7 45.139 45.139 0 0 1-8.86-10.12 22.53 22.53 0 0 1-3.66-12.38v-42.07h66.43v31.95h75.29V344.3H378.3a28.72 28.72 0 0 1-11.07-2.53Z'
      style={{
        strokeMiterlimit: 10,
        fill: 'none',
        stroke: '#052146',
        strokeWidth: 7
      }}
    />
  </svg>
);

export default Logo;
