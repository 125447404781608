export const INITIAL_STATE = {
  entryFee: 0,
  prizePool: 0,
  stamina: 0,
  prizeInToken: 'ESTAR',
  maxPlayers: 500,
  end: '11/11/2022 23:59',
  type: 'Daily',
  description: '',
  isActive: true,
};

export const formReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_INPUT':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};
