import React from "react";
import Carousel from "react-bootstrap/Carousel";
import img1 from "../../assets/img/1.png";
import img2 from "../../assets/img/2.png";
import img3 from "../../assets/img/3.png";

const CarouselComponent = () => {
  return (
    <Carousel>
      <Carousel.Item interval={5000}>
        <a
          href="https://www.frameit.gg/marketplace/EQUISTAR-3f393f/items"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="d-block w-100" src={img1} />
        </a>
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <a
          href="https://raffle.equistar.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="d-block w-100" src={img2} />
        </a>
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <a
          href="https://www.estar.games/whitepaper.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="d-block w-100" src={img3} />
        </a>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
