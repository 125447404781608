import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { tokenName } from "../../../config";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import styles from "./styles.module.scss";
import { socket } from "../../../config";
import ModalPay from "./ModalPay";

const Button = ({ race }) => {
  const navigate = useNavigate();
  const { address, account } = useGetAccountInfo();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [estarBalance, setEstarBalance] = useState(0);
  const egldBalance = account.balance / 1000000000000000000;

  useEffect(() => {
    fetch(`https://api.elrond.com/accounts/${address}/tokens/ESTAR-afaaf0`)
      .then((response) => response.json())
      .then((data) => setEstarBalance(data.balance));
  }, []);

  function available(race) {
    socket.emit("get-available", race, address);
    socket.once("recive-available", (mesaj, raceBE) => {
      if (mesaj === "OK") {
        raceBE.entryFee === 0
          ? navigate(`/enter/race/${raceBE._id}`, {
              state: { race: raceBE },
            })
          : setShow(true);
        setMessage(mesaj);
      }
      if (mesaj !== "OK") {
        setMessage(mesaj);
        setShow(true);
      }
    });
  }

  return (
    <>
      <button
        id={race._id}
        className={styles.btn}
        onClick={(e) => {
          available(race, e.target.id);
        }}
      >
        {race.entryFee === 0
          ? "FREE"
          : race.with === "ESTAR"
          ? `${race.entryFee} Estar`
          : `${race.entryFee} EGLD`}
      </button>
      <div>
        <ModalPay
          estarBalance={estarBalance}
          egldBalance={egldBalance}
          race={race}
          show={show}
          tokenName={tokenName}
          setShow={setShow}
          message={message}
          setMessage={setMessage}
        />
      </div>
    </>
  );
};

export default Button;
