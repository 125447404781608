import { spawn } from 'child_process';
import React from 'react';
import styles from './styles.module.scss';

function ColumnFilter({ column }) {
  const { filterValue, setFilterValue } = column;
  return (
    <span>
      <input
        value={filterValue || ''}
        onChange={(e) => setFilterValue(e.target.value)}
        placeholder="Search"
        className={styles.search}
      ></input>
    </span>
  );
}

export default ColumnFilter;
