import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import { socket } from "../../../config";
import styles from "../styles.module.scss";
import Counter from "../Components/Counter";
import { Col, Container, Modal, Row } from "react-bootstrap";

const RaceView = () => {
  const { address } = useGetAccountInfo();
  const { pathname, hash, state } = useLocation();
  const path = pathname?.replace("/race/", "");
  const id = path + hash;
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(true);
  const [exist, setExist] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [race, setRace] = useState([]);
  const [players, setPlayers] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [clickable, setClickable] = useState(false);
  const [skeleton, setSkeleton] = useState(false);

  const navigate = useNavigate();
  const handleRedirect = () =>
    Boolean(address) ? setLoading(false) : navigate("/unlock");
  useEffect(() => {
    socket.emit("get-race", { id, address });
    const handler = ({ race, status, authorized }) => {
      if (race != null) {
        setRace(race);
        setStatus(status);
        setExist(true);
        setAuthorized(authorized);
      }
    };
    socket.emit("get-players", path);

    socket.on("recive-players", setPlayers);
    socket.on("recive-race", handler);
    setTimeout(() => {
      setSkeleton(true);
    }, 1000);
  }, [clickable]);

  useEffect(handleRedirect, [address]);
  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function (event) {
      window.location.replace(`/race`);
    });
    setShowSelect(state !== null || "" ? true : false);
  }, []);

  return (
    <>
      <Modal
        show={showSelect}
        backdrop="static"
        keyboard={false}
        size="md-down"
        onHide={() => {
          setShowSelect(false);
          setClickable(true);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-white" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            😊 Hey, welcome in the race!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white px-3">
          <h4>{state?.message}</h4>
        </Modal.Body>
      </Modal>

      {skeleton === false ? (
        <Container>
          <Row>
            <h1 className={styles.wrong}>Select a horse</h1>
            <Row className="justify-content-center">
              <Col className="col-lg-3 col-m-3 col-6 col-s-12 mx-5 text-center">
                <Row className={`${styles.loading} my-5`}>
                  <h2 className={styles.wrong}>ESTAR Horse</h2>
                  <div className={`${styles.bar}  ${styles.race} `}></div>
                </Row>
              </Col>
              <Col className="col-lg-3 col-m-3 col-6 col-s-12 mx-5 text-center">
                <Row className={`${styles.loading} my-5`}>
                  <h2 className={styles.wrong}>ESTAR Horse</h2>
                  <div className={`${styles.bar}  ${styles.race} `}></div>
                </Row>
              </Col>
              <Col className="col-lg-3 col-m-3 col-6 col-s-12 mx-5 text-center">
                <Row className={`${styles.loading} my-5`}>
                  <h2 className={styles.wrong}>ESTAR Horse</h2>
                  <div className={`${styles.bar}  ${styles.race} `}></div>
                </Row>
              </Col>
              <Col className="col-lg-3 col-m-3 col-6 col-s-12 mx-5 text-center">
                <Row className={`${styles.loading} my-5`}>
                  <h2 className={styles.wrong}>ESTAR Horse</h2>
                  <div className={`${styles.bar}  ${styles.race} `}></div>
                </Row>
              </Col>
            </Row>
          </Row>
        </Container>
      ) : (
        <div className="container">
          {state !== null ? (
            <Modal
              onHide={() => setShowSelect(false)}
              onClick={() => setClickable(true)}
            >
              <Modal.Header className="bg-white" closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {state}
                </Modal.Title>
              </Modal.Header>
            </Modal>
          ) : null}

          {exist ? (
            <>
              {authorized ? (
                <>
                  {status ? (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <h1 className={styles.raceViewTitle}>
                            Race {race._id}
                          </h1>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h4 className={styles.raceViewPlayers}>
                            Players: {race.player.length}/8
                          </h4>
                          {race.player.length === 8 ? (
                            <Counter status={status} />
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h3 className={styles.raceViewParticipants}>
                            <span>[ </span>Race participants<span> ]</span>
                          </h3>
                        </div>
                      </div>
                      <div className="row">
                        {/* <RaceStart race={race} /> */}
                        {race.player.map((player) => (
                          <div key={player.horse} className="col-12 col-md-4">
                            <div className={styles.raceViewCard}>
                              <div className={styles.raceViewCardContent}>
                                <img
                                  className={styles.raceViewCardContentImg}
                                  src={player.horseUrl}
                                />
                                <h4
                                  className={
                                    styles.raceViewCardContentHorseName
                                  }
                                >
                                  {player.horse}
                                </h4>
                                <h5
                                  className={styles.raceViewCardContentAddress}
                                >
                                  Address:{" "}
                                  <small>
                                    {player.address.substr(0, 8) +
                                      " ... " +
                                      player.address.substr(
                                        player.address.length - 8
                                      )}
                                  </small>
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-12 text-white text-center">
                          <h1 className={styles.raceViewTitle}>
                            Race {race._id} is over!
                          </h1>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-4">
                          <div className={styles.raceViewCard + " mt-3"}>
                            <div className={styles.raceViewCardContent}>
                              <img
                                className={styles.raceViewCardContentImg}
                                src={
                                  "https://media.elrond.com/nfts/asset/QmeQ1XEMuQDnQsWEMuZXhCj2ofSErKjqcWk8bbhh8bpYhL/" +
                                  race.winners[1].horse.slice(13) +
                                  ".png"
                                }
                              />
                              <h4
                                className={styles.raceViewCardContentHorseName}
                              >
                                {race.winners[1].horse}
                              </h4>
                              <h5 className={styles.raceViewCardContentAddress}>
                                Address:{" "}
                                <small>
                                  {race.winners[1].address.substr(0, 8) +
                                    " ... " +
                                    race.winners[1].address.substr(
                                      race.winners[1].address.length - 8
                                    )}
                                </small>
                              </h5>
                              <h4 className={styles.raceViewCardContentAddress}>
                                Score: {race.winners[1].score}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className={styles.raceViewCard}>
                            <div className={styles.raceViewCardContent}>
                              <img
                                className={styles.raceViewCardContentImg}
                                src={
                                  "https://media.elrond.com/nfts/asset/QmeQ1XEMuQDnQsWEMuZXhCj2ofSErKjqcWk8bbhh8bpYhL/" +
                                  race.winners[0].horse.slice(13) +
                                  ".png"
                                }
                              />
                              <h4
                                className={styles.raceViewCardContentHorseName}
                              >
                                {race.winners[0].horse}
                              </h4>
                              <h5 className={styles.raceViewCardContentAddress}>
                                Address:{" "}
                                <small>
                                  {race.winners[0].address.substr(0, 8) +
                                    " ... " +
                                    race.winners[0].address.substr(
                                      race.winners[0].address.length - 8
                                    )}
                                </small>
                              </h5>
                              <h4 className={styles.raceViewCardContentAddress}>
                                Score: {race.winners[0].score}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className={styles.raceViewCard + " mt-4"}>
                            <div className={styles.raceViewCardContent}>
                              <img
                                className={styles.raceViewCardContentImg}
                                src={
                                  "https://media.elrond.com/nfts/asset/QmeQ1XEMuQDnQsWEMuZXhCj2ofSErKjqcWk8bbhh8bpYhL/" +
                                  race.winners[2].horse.slice(13) +
                                  ".png"
                                }
                              />
                              <h4
                                className={styles.raceViewCardContentHorseName}
                              >
                                {race.winners[2].horse}
                              </h4>
                              <h5 className={styles.raceViewCardContentAddress}>
                                Address:{" "}
                                <small>
                                  {race.winners[2].address.substr(0, 8) +
                                    " ... " +
                                    race.winners[2].address.substr(
                                      race.winners[2].address.length - 8
                                    )}
                                </small>
                              </h5>
                              <h4 className={styles.raceViewCardContentAddress}>
                                Score: {race.winners[2].score}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h3 className={styles.raceViewParticipants}>
                            <span>[ </span>Race participants<span> ]</span>
                          </h3>
                        </div>
                      </div>
                      <div className="row">
                        {race.player.map((player) => (
                          <div key={player.horse} className="col-12 col-md-4">
                            <div className={styles.raceViewCard}>
                              <div className={styles.raceViewCardContent}>
                                <h4
                                  className={
                                    styles.raceViewCardContentHorseName
                                  }
                                >
                                  {player.horse}
                                </h4>
                                <h5
                                  className={styles.raceViewCardContentAddress}
                                >
                                  Address:{" "}
                                  <small>
                                    {player.address.substr(0, 8) +
                                      " ... " +
                                      player.address.substr(
                                        player.address.length - 8
                                      )}
                                  </small>
                                </h5>
                                <h5
                                  className={styles.raceViewCardContentAddress}
                                >
                                  Score: <small>{player.score}</small>
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <h1 className="text-white text-center">
                      This race has not started yet!
                    </h1>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="row">
              <div className="col-12">
                <h1 className="text-white text-center">
                  This race doesn't exist!
                </h1>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RaceView;
