import React, { useState } from 'react';
import TournamentCreateModal from './TournamentCreateModal';

const Create = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <button onClick={() => setShow(!show)} className="btn btn-primary">
        Create Tournament
      </button>
      <TournamentCreateModal
        show={show}
        setShow={setShow}
        typeOfModal="CREATE"
      />
    </>
  );
};

export default Create;
