import { useEffect, useState } from "react";
import styles from "../styles.module.scss";

function Counter() {
  const [counter, setCounter] = useState(localStorage.getItem("counter"));
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    counter > 0
      ? setTimeout(() => setCounter(counter - 1), 1000)
      : window.location.reload();
    setInProp(true);
    localStorage.setItem("counter", counter);
  }, [counter]);

  useEffect(() => {
    localStorage.setItem("counter", 120);
  }, []);

  return (
    <div className={styles.counter}>
      The race will end in{" "}
      {`${Math.trunc(localStorage.getItem("counter") / 60)} : ${
        localStorage.getItem("counter") % 60
      }`}
    </div>
  );
}

export default Counter;
